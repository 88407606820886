import React from 'react';
import BigIcon from '../BigIcon';
import WeddingCat from './WeddingCat';

const Wedding = ({ cat1, cat2 }) => {

    const loveIcon = { iconUrl: "/Images/Wedding/love.png", iconAlt:"love", iconClass:"heart-beating" };

    return (
        <div className='wedding-container'>            
            <WeddingCat data={cat1}/>
            <BigIcon iconUrl={loveIcon.iconUrl} iconAlt={loveIcon.iconAlt} iconClass={loveIcon.iconClass}/>
            <WeddingCat data={cat2}/>
        </div>
    );
};

export default Wedding;