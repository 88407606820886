import React from 'react';
import { logEvent } from "firebase/analytics";

const NotFound = ({ analytics }) => {
    logEvent(analytics, 'page_not_found');

    return (
        <div className='not-found'>
            <img src='/Images/NotFound/pic.jpg' alt='not-found' />
            <div className='not-found-text'>
                <h2>Chat alors...</h2>
                <p>La page que vous essayez d'afficher n'est pas disponible.</p>
            </div>
        </div>
    );
};

export default NotFound;