import React from 'react';
import { salesConditionsText } from '../../content/salesConditions';

const SalesConditions = () => {
    return (
        <div className='presentation-container'>
            <br /><br />
            <h2>Conditions de vente</h2>
            <pre className="tab">{salesConditionsText}</pre>
        </div>
    );
};

export default SalesConditions;