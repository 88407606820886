import React from 'react';

const NavMenuLink = ({ link, onSelectedHandle }) => {

     const elementId = '#' + link.elementId;
    // function scrollToElement(id) {
    //     const section = document.getElementById('')
    //     const e = document.getElementById(id)
    //     if (section != null && e != null)
    //         section.scrollTo(e);
    // }

    return (
        <div className='nav-menu-link'>
            <a href={elementId} onClick={() => onSelectedHandle(link.elementId)}>{link.label}</a>
        </div>
    );
};

export default NavMenuLink;