export const informationPresentationText = "C'est en 1926 que l'on découvre le Sacré de Birmanie en exposition féline.\nPoupée de Maldapour y fait son apparition avec deux autres représentants de la race.\n\nPuis, en janvier 1927 on retrouve les trois mêmes chats. Manou de Maldapour y est présentée en vedette.\n\nEn 1928 c'est au tour de Poupée de Maldapour d'être la favorite.\n\nEn avril 1931, Reine de Rangoon et Dieu d'Arakan raflent les premiers pris à Reims.\n\nEn janvier 1932, Dieu d'Arakan et Lon Saïto de Maldapour sont champions internationaux.\n\nA partir de là beaucoup de champions birmans triomphent dans les expositions.\n\nAprès la dernière guerre mondiale il ne restait qu'un couple de birmans (Orloff et Xénia de Kaabaa) dont descendent ainsi qu'en témoigne leLivre des Origines de la Fédération Féline Française tous les chats de cette race existants actuellement dans le monde.\n\nC'est grâce au travail acharné de quelques éleveuses comme Madeleine Boyer (chatterie de Kaabaa) ou Simone Poirier (chatterie de Crespières)que la race reviendra au premier plan après cette triste période.\n\nQuelques croisements avec le Persan furent alors effectués afin de limiter la consanguinité et pour retrouver le type d'avant-guerre.\n\nEn 1950 la race prend officiellement le nom de Chat Sacré de Birmanie !";
export const informationStandardText =[
    "Le Sacré de Birmanie est un chat élégant de taille moyenne.\n\nSa robe est mi-longue et son poil est très soyeux.\n\nSon corps est puissant et massif avec une ossature lourde et un dos droit et régulier.\n",
    "Sa tête est de taille moyenne et large avec des joues pleines, un front légèrement arrondi et un menton fort.",
    "Son nez est busqué : nez dit « romain »",
    "Ses yeux sont bleus et grands, de forme ovale et bien espacés l’un de l’autre.\n\nSes oreilles ne sont pas trop grandes et larges à la base. Elles sont bien espacées et « plantées » sur les côtés de la tête.\n\nSa queue est de taille moyenne, large à la base et bien proportionnée avec le corps.",
    "Ses pattes sont bien droites et robustes.\n\nA l’avant comme à l’arrière on retrouve des gants blancs et régulier qui se termine idéalement à la jonction entre le pied et la patte.\n\nÀ l’arrière, les gants blancs remontent sur le derrière de la patte et se terminent en pointe de la moitié aux trois-quarts du talon (éperons)."
];

export const informationPersonalityText =[
    "Le Sacré de Birmanie est un chat équilibré et facile à vivre.\n\n",
    "Indépendant, il reste cependant très sensible et proche de son humain.\n\n",
    "Très sociable, il aime la compagnie des enfants et des autres animaux.\n\n",
    "Un tantinet « peau de colle », à la maison il vous suit partout, d’où son surnom de Chat-Chien.\n\n",
    "C’est un chat très intelligent et curieux qui saura vous charmer par son bavardage et son élégance.\n\n",
    "Loin d’être un animal « potiche », le Sacré de Birmanie est vif et très actif.\n",
    "Il est toujours prêt pour une partie de jeu.\n\n",
    "Son regard bleu envoutant reflète cette part de mystère qui le lie à sa belle légende.\n\n",
    "C’est LE compagnon idéal !"
];

export const informationMaintenanceText = [
    "L’entretien du Sacré de Birmanie ne pose pas de problème particulier.\nUn brossage effectué soigneusement une fois par semaine suffit afin de prévenir les nœuds et maintenir la propreté du poil et de la peau. \nToutefois, ces brossages doivent devenir plus fréquents en période de mue.\n\n",
    "Même si en général il n’a pas besoin d’être lavé, le familiariser au ",
    "bain",
    " est un plus. En effet, des petits accidents de la vie peuvent nécessiter un nettoyage sous l’eau et un chat qui n’est pas habitué aura pour reflèxe de se débattre.\n\n",
    "Pour son confort, il est fortement conseillé de faire l’acquisition d’un arbre à chat.\nIl pourra ainsi s’y faire les griffes sur la partie en sisal.\nCependant, une petite ",
    "taille des griffes",
    " est également conseillée une fois par mois afin de l’habituer à cette manipulation.\n\n",
    "(En vieillissant, le chat se fait de moins en moins les griffes et celles-ci poussent plus vite. Il faut donc les couper régulièrement afin d’éviter qu’elles ne poussent trop et se plantent à l’intérieur du coussinet).\n\n",
    "Hebdomadairement, il faut également lui ",
    "nettoyer les yeux",
    " avec une gaze et une lotion oculaire. Les poussières ont en effet tendance à se déposer au coin de l’œil et avec le temps peuvent former des petites croutes difficiles à enlever. \n\n",
    "L’entretien de son compagnon passe aussi par une alimentation saine.\nBien que nécessaires pour l’élimination des boules de poils et l’entretien de la dentition, les croquettes ne doivent pas être l’aliment de base du Sacré de Birmanie, mais seulement un complément. Le chat est avant tout un carnivore et une alimentation ménagère est à privilégier. De la viande (poulet, bœuf) coupé en morceaux, du poisson agrémenté de petits dés de légumes (courgette, haricot, carotte, courge) font un repas idéal. Et pour les petites collations, un peu de fromage de chèvre, quelques bouts de jambon, une noisette de beurre… les idées sont nombreuses et le chat est un fin gourmet. \n\n"
];