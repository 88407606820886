import React, { useState, useEffect, useRef } from 'react';
import emailjs from 'emailjs-com';

const MailForm = () => {

    let [data, setData] = useState(JSON.parse(localStorage.getItem('messageSave')) || { userName: '', userMail: '', userPhone: '', userMessage: '' });

    useEffect(() => {
        document.getElementById('name').value = data.userName;
        document.getElementById('mail').value = data.userMail;
        document.getElementById('phone').value = data.userPhone;
        document.getElementById('msg').value = data.userMessage;
    });

    const handleChange = () => {
        setData(
            {
                userName: document.getElementById('name').value,
                userMail: document.getElementById('mail').value,
                userPhone: document.getElementById('phone').value,
                userMessage: document.getElementById('msg').value
            });
        localStorage.setItem('messageSave', JSON.stringify(data));
    };

    const handleClick= () => {
        localStorage.removeItem('messageSave');
    }

    const form = useRef();
    const sendEmail = (e) => {

        e.preventDefault();
        if (
            document.getElementById('name').value.length > 0 &&
            document.getElementById('mail').value.length > 0 &&
            document.getElementById('phone').value.length > 0 &&
            document.getElementById('msg').value.length > 0
        ) {
            emailjs.sendForm('service_g1xb7f1', 'template_tgwjtxh', form.current, 'user_amtArFRCH3smymGzAl8K0')
                .then((result) => {
                    alert('Le message a bien été transféré aux administrateurs de kesardeling.fr');
                }, (error) => {
                    alert('Le service est temporairement indisponible. Veuillez nous excuser pour la gêne occasionnée');
                });
        } else {
            alert('Veuillez vérifier votre saisie');
        }
    };

    return (
        <div className='modal' >
            <form ref={form} className='mail-form' onSubmit={sendEmail}>
                <div id="userName">
                    <label for="name">Nom</label><br />
                    <input type="text" id="name" name="from_name" onChange={handleChange} />
                </div>

                <div id="userMail">
                    <label for="mail">Email</label><br />
                    <input type="email" id="mail" name="from_email" onChange={handleChange} />
                </div>

                <div id="userPhone">
                    <label for="phone">Numéro de téléphone</label><br />
                    <input type="phone" id="phone" name="from_phone" onChange={handleChange} />
                </div>
                <br />
                <div id="message">
                    <label for="msg">Votre message </label><br />
                    <textarea id="msg" name="message" onChange={handleChange} />
                </div><br /><br />
                <input type="submit" value="Envoyer" onClick={handleClick}/>
            </form>
        </div>
    );
};

export default MailForm;