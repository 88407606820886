import React, { useState, useEffect } from 'react';
import Cat from './Cat';

const CatList = ({ firestore }) => {

    let [data, setData] = useState({ cat: [] });

    useEffect(() => {
        async function getCats() {
            const newData = { ...data };
            const catRef = firestore.collection('cat');
            catRef.where('ownership', '==', true).orderBy('birthdate', 'desc').get().then(querySnapshot => {
                const catList = querySnapshot.docs.map(doc => doc.data());
                newData.cat = catList;
                setData(newData);

                const e = document.getElementById('body')
                if (e != null)
                    e.scrollIntoView({ behavior: 'smooth' });
            });
        }

        getCats();
    }, [firestore])

    return (
        <div id='cattery-cat-list' className='cattery-cat-list nav-selector'>
            <br /><br />
            <h1>Nos félins</h1>
            <ul>
                {data.cat.length < 0 ?
                    <div className='loader'>Chargement...</div> :
                    <>{data.cat.map(cat => <li key={cat.short_name} ><Cat data={cat} /></li>)}</>
                }
            </ul>
        </div>
    );
};

export default CatList;