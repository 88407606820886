import React from 'react';
import SocialIcon from './SocialIcon';

const Footer = ({analytics}) => {
    return (
        <div className='footer'>
            <div className="social-wrapper">
                <SocialIcon analytics={analytics} iconClassName='icon facebook' spanClassName='fab fa-facebook' url='https://www.facebook.com/profile.php?id=100008178677285' />
                <SocialIcon analytics={analytics} iconClassName='icon instagram' spanClassName='fab fa-instagram' url='https://www.instagram.com/maryszy42' />
                <SocialIcon analytics={analytics} iconClassName='icon whatsapp' spanClassName='fab fa-whatsapp' url='https://wa.me/0650263359' />
            </div>
        </div>
    );
};

export default Footer;