import './styles/App.css';
import './styles/Navbar.css';
import './styles/Cat.css';
import './styles/Footer.css';
import './styles/Photo.css';
import './styles/Information.css';
import './styles/NotFound.css';
import './styles/NavMenu.css';
import './styles/WeddingKittens.css';
import './styles/BigIcon.css';
import './styles/ContactUs.css';
import './styles/Map.css';
import './styles/MailForm.css';
import './styles/Modal.css';
import 'react-image-lightbox/style.css';
import React, { Component } from 'react';
import Body from './components/Body';
import Footer from './components/Footer';
import Header from './components/Header';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAnalytics } from "firebase/analytics";
import{ init } from 'emailjs-com';

const firebaseConfig = {
  apiKey: "AIzaSyAlDB2woH5XlWHUDyMlcNbebVI5-FWINGo",
  authDomain: "kesar2-39706.firebaseapp.com",
  projectId: "kesar2-39706",
  storageBucket: "kesar2-39706.appspot.com",
  messagingSenderId: "206405954891",
  appId: "1:206405954891:web:55ffbf8ff269d9a93c4cce",
  measurementId: "G-NZSS8ME0N9"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const analytics = getAnalytics();
init("user_amtArFRCH3smymGzAl8K0");

class App extends Component {

  render() {
    return (
      <div className="App">
        <Header />
        <Body firestore={firestore} analytics={analytics}/>
        <Footer analytics={analytics}/>
      </div>
    );
  }
}
export default App;
