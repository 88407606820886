import React from 'react';
import { informationPersonalityText } from '../../content/information';
import InformationImage from './InformationImage';

const InformationPersonality = () => {

    const rootImgPath = '/Images/Information/Personality/'
    const images1 = [{ imgPath: rootImgPath + 'img1.jpg', imgAlt: 'sleep' }];
    const images2 = [{ imgPath: rootImgPath + 'img2.jpg', imgAlt: 'play1' }, { imgPath: rootImgPath + 'img3.jpg', imgAlt: 'baby1' }];
    const images3 = [{ imgPath: rootImgPath + 'img4.jpg', imgAlt: 'pose1' }, { imgPath: rootImgPath + 'img5.jpg', imgAlt: 'pose2' }];
    const images4 = [{ imgPath: rootImgPath + 'img6.jpg', imgAlt: 'play2' }];
    const images5 = [{ imgPath: rootImgPath + 'img7.jpg', imgAlt: 'baby2' }];

    return (
        <div id='cat-information-personality' className='nav-selector'>
            <br /><br />
            <h1>Caractère</h1>
            <pre className="tab">{informationPersonalityText[0]}</pre>
            <InformationImage images={images1} />
            <pre className="tab">{informationPersonalityText[1]}</pre>
            <InformationImage images={images2} />
            <pre className="tab">{informationPersonalityText[2]}</pre>
            <pre className="tab">{informationPersonalityText[3]}</pre>
            <pre className="tab">{informationPersonalityText[4]}</pre>
            <InformationImage images={images3} />
            <pre className="tab">{informationPersonalityText[5]}</pre>
            <InformationImage images={images4} />
            <pre className="tab">{informationPersonalityText[6]}</pre>
            <InformationImage images={images5} />
            <pre className="tab">{informationPersonalityText[7]}</pre>
            <pre className="tab">{informationPersonalityText[8]}</pre>
            </div>
    );
};

export default InformationPersonality;