import React from 'react';

const InformationImage = ({ images }) => {

    let element = [];
    images.forEach((image, index) => {
        element.push(<li key={image.imgPath}><img src={image.imgPath} alt={image.imgAlt} /></li>);
    });

    return (
        <div className="information-image-container">
            <ul className="information-image-container-list">
                {element}
            </ul>
        </div>
    );
};

export default InformationImage;