import React from 'react';

const Kitten = ({ kitten }) => {

    const colorText = 'Couleur présumée : '+kitten.color;

    const maleRessource = { imgPath: '/Images/Wedding/male.png', altText:'male' }
    const femaleRessource = { imgPath: '/Images/Wedding/female.png', altText:'female' }

    const imagePath = '/Images/Wedding/Kittens/' + kitten.name + '.jpg'

    return (
        <div className='kitten-container'>
            <img className='kitten-container-image' src={imagePath} alt={kitten.name} />
            <div className="kitten-details">
                <span className="kitten-gender">
                        {
                            kitten.male ?
                                <img src={maleRessource.imgPath} alt={maleRessource.altText} /> :
                                <img src={femaleRessource.imgPath} alt={femaleRessource.altText} />
                        }
                    </span>
                <div className="kitten-title">
                    <span className="kitten-name">{kitten.name}</span>
                    <span className="kitten-color">{colorText}</span>
                   
                </div>
            </div>
        </div>
    );
};

export default Kitten;