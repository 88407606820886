import React from 'react';

const Cherub = () => {
    return (
        <div id='cattery-cherub' className='nav-selector'>

            <br /><br />
            <h1>Nos chérubins</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure ab recusandae dolore facere! Dicta, ullam impedit nulla quo omnis quam. Iusto quae officia, at ducimus tempora cupiditate nam, architecto officiis similique, placeat temporibus. Dignissimos cumque est deserunt consequuntur quaerat natus magni soluta praesentium ipsa, a commodi, dolorem quibusdam magnam error enim voluptatibus molestiae vel asperiores exercitationem repellendus nihil! Minus suscipit facere quasi aspernatur, alias, optio iste, possimus id qui eos dolor? Minus in assumenda aliquam quae accusamus asperiores dicta laborum reiciendis vel repellat, nulla sit quisquam necessitatibus, quis placeat iusto adipisci repellendus voluptas molestiae totam eaque. Tempore fugit repellat reprehenderit similique vitae eum tempora unde aliquam magni ipsam deserunt alias, et nesciunt facilis eveniet nam? Omnis inventore ipsam aut eaque ipsa quaerat? Cupiditate at ex nulla. Eaque inventore vel officiis aperiam facere laudantium dolor veritatis quaerat, veniam odit. Aut sequi magnam sunt veritatis distinctio dignissimos laboriosam hic molestiae temporibus error ad obcaecati adipisci placeat aliquid eos, architecto soluta consectetur libero explicabo ipsa? Sequi quam necessitatibus ullam nostrum doloremque veniam dolorum dignissimos, ducimus eveniet distinctio, at dolores, pariatur praesentium corporis aspernatur vitae. Iste tempore tempora temporibus doloribus minima obcaecati. Non ex nesciunt nihil obcaecati consequuntur. Quae maiores, voluptate quidem accusamus iste mollitia id ut labore, consequatur impedit dolores non perferendis facere porro, nostrum aspernatur velit. Magni laboriosam natus veritatis aliquid odit incidunt a ea? Consequatur veritatis aut, nemo veniam nobis omnis possimus a sint modi repellendus tenetur alias non eum deserunt dolorem repellat! Harum similique quis veritatis cumque ipsam minus eius ullam. Sunt repellendus eaque eveniet cumque!</p>

        </div>
    );
};

export default Cherub;