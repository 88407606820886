import React, { useState, useEffect, Component } from 'react';
import { logEvent } from "firebase/analytics";
import FindUs from './FindUs';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import MailForm from './MailForm';
import { contactUsText } from '../../content/contactUs';
import ReactTooltip from "react-tooltip";


const ContactUs = ({ analytics }) => {

    let [data, setData] = useState({ mailCopied: false, phoneCopied: false });


    const e = document.getElementById('body')
    if (e != null)
        e.scrollIntoView({ behavior: 'smooth' });


    useEffect(() => {
        resetBodyOverflow();
    });

    const onOpenCloseHandler = () => {
        resetBodyOverflow();
    };

    // à cause de la popup
    const resetBodyOverflow = () => {
        const e = document.querySelector('body')
        e.style.overflow = null;
    };

    const handleStampClick = (e) => {
        const stampElementId = e.target.getAttribute('id');
        const infoElementId = stampElementId + 'Value';

        logEvent(analytics, 'display_' + stampElementId);

        const stampElement = document.getElementById(stampElementId);
        const infoElement = document.getElementById(infoElementId);
        stampElement.style.display = 'none';
        infoElement.style.display = 'block';
    };

    const handleTextClick = (e) => {
        navigator.clipboard.writeText(e.target.innerHTML);

        let newData = { ...data };

        switch (e.target.getAttribute('id')) {
            case 'mailContactText':
                newData.mailCopied = true;
                break;
            case 'phoneContactText':
                newData.phoneCopied = true;
                break;
            default:
                break;
        }
        
        setData(newData);
    };


    return (
        <div className='contact-us'>

            <div className="presentation-container">
                <h1>Nous contacter</h1>
                <pre className="tab">
                    {contactUsText}
                </pre>

                <div className='buttons-container'>
                    <button id='mailContact' className='contact-us-stamp' onClick={(e) => handleStampClick(e)}>Voir l'adresse mail</button>
                    <div data-tip data-for="info-tooltip-mail" id='mailContactValue' className='contact-us-text-container'>
                        <p id='mailContactText' className="contact-us-text" onClick={(e) => { handleTextClick(e) }}>kesardeling@gmail.com</p>
                        <ReactTooltip id='info-tooltip-mail' place="bottom" type="dark" effect="solid">
                            {!data.mailCopied ? <span>Cliquez pour copier</span> : <span>✔️ texte copié !</span>}
                        </ReactTooltip>
                    </div>

                    <button id='phoneContact' className='contact-us-stamp' onClick={(e) => handleStampClick(e)}>Voir le numéro de téléphone</button>
                    <div data-tip data-for="info-tooltip-phone" id='phoneContactValue' className='contact-us-text-container'>
                        <p id='phoneContactText' className="contact-us-text" onClick={(e) => { handleTextClick(e) }}>06 50 26 33 59</p>
                        <ReactTooltip id='info-tooltip-phone' place="bottom" type="dark" effect="solid">
                            {!data.phoneCopied ? <span>Cliquez pour copier</span> : <span>✔️ texte copié !</span>}
                        </ReactTooltip>
                    </div>

                    <Popup
                        trigger={open => (
                            <button className="contact-us-stamp">Envoyer un message</button>
                        )}
                        position="right center"
                        modal
                        lockScroll
                        onOpen={onOpenCloseHandler}
                        onClose={onOpenCloseHandler}
                    >
                        <MailForm />
                    </Popup>
                </div>

                <br /><br />
                <FindUs />
            </div>
        </div >
    );
};

export default ContactUs;