import React from 'react';

const BigIcon = ({ iconUrl, iconAlt, iconClass }) => {
    return (
        <div className='bigicon'>
            <img src={iconUrl} className={iconClass} alt={iconAlt} />
        </div>
    );
};

export default BigIcon;