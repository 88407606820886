import React from 'react';
import { homeText } from '../content/homeContent';
import Photo from './Photo';


const Home = () => {

    const e = document.getElementById('body')
    if (e != null)
        e.scrollIntoView({ behavior: 'smooth' });

    const photos = [
        { url: "/Images/Home/image1.jpg", alt:"Mulan", text:"Le temps passé avec un chat n’est jamais perdu (Colette)" },
        { url: "/Images/Home/image2.jpg", alt:"Embellie" , text:"Les chats sont les gardiens de nos silences (Marcel Ohayon)" },
        { url: "/Images/Home/image3.jpg", alt:"Byblos" , text:"Le chat signe chacune de ses pensées avec sa queue (Ramon Gomez)" },
        { url: "/Images/Home/image4.jpg", alt:"Uline" , text:"Les jours se suivent, le chat s’étire (Marcel Ohayon)" },
        { url: "/Images/Home/image5.jpg", alt:"Heva" , text:"Le chat est le seul animal qui soit arrivé à domestiquer l’homme (Marcel Mauss)" },
        { url: "/Images/Home/image6.jpg", alt:"Bebe" , text:"Nourrir un chat, c’est entretenir le mystère (Marcel Ohayon)" },
        { url: "/Images/Home/image7.jpg", alt:"Uline" , text:"L’idéal du calme est dans un chat assis (Jules Renard)" },
        { url: "/Images/Home/image8.jpg", alt:"Uline" , text:"L’ hivers vient (Game of Thrones)" }
    ];

    return (
        <div className='home'>
            <div className="presentation-container">
                <br /><br />
                <pre className='tab'>{homeText}</pre>
                <br /><br />
            </div>
            <br /><br />
            <div className='photo-container'>
                {photos.map((photo) => { return <Photo photo={photo} /> })}
            </div>
            <br /><br />
        </div>
    );
};

export default Home;