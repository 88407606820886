import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

// ...

export class MapContainer extends React.Component {

  render() {
    return (
      <Map google={this.props.google} zoom={14} initialCenter={{ lat: 45.5335519, lng: 4.6044079 }} options={{
        styles:
          [{
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          ]
      }} 
      className={'map'}>


        <Marker onClick={this.onMarkerClick}
          title={'15 Route de Saint Genis, 42800 Rive de Gier'}
          name={'Chaterie Kesar de Ling'}
          position={{ lat: 45.534128, lng: 4.6067989 }} />

        <InfoWindow onClose={this.onInfoWindowClose}>
          <div>
            <h1>Test</h1>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAlDB2woH5XlWHUDyMlcNbebVI5-FWINGo",
  language: 'fr-FR'
})(MapContainer)

