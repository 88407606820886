import React, { useState, useEffect } from 'react';
import Kittens from './KittenList';
import SalesConditions from './SalesConditions';
import Wedding from './Wedding';
import { weddingFalseText } from '../../content/weddingFalseText';
import { weddingTrueText } from '../../content/weddingTrueText';

const WeddingKittens = ({ firestore }) => {

    let [data, setData] = useState({ cat: [], kittens: [], message: '', dateText: '' });

    useEffect(() => {
        async function getKittens() {
            const newData = { ...data };
            const catRef = firestore.collection('cat');
            const weddingRef = firestore.collection('wedding');
            const kittensRef = firestore.collection('kittens');
            weddingRef.where('active', '==', true).limit(1).get().then(querySnapshot => {
                const wedding = querySnapshot.docs.map(doc => doc.data());

                if (wedding.length > 0) {

                    const options = { year: 'numeric', month: 'long', day: 'numeric' };
                    const formatedDate = new Date(wedding[0].kittens_born_date.toDate()).toLocaleDateString("fr-FR", options);
                    newData.dateText = "Naissances du " + formatedDate;

                    newData.message = weddingTrueText;
                    catRef.where('sk', 'in', [wedding[0].cat1_id, wedding[0].cat2_id]).get().then(querySnapshot => {
                        const catList = querySnapshot.docs.map(doc => doc.data());
                        newData.cat = catList;
                        kittensRef.where('wedding_id', '==', wedding[0].sk).get().then(querySnapshot => {
                            const kittendList = querySnapshot.docs.map(doc => doc.data());
                            newData.kittens = kittendList;
                            setData(newData);
                            const e = document.getElementById('body')
                            if (e != null)
                                e.scrollIntoView({ behavior: 'smooth' });  
                            
                        });
                    });
                }
                else {
                    newData.message = weddingFalseText;
                    setData(newData);
                    const e = document.getElementById('body')
                    if (e != null)
                        e.scrollIntoView({ behavior: 'smooth' });  
                    
                }
            });
        }
        getKittens();
    }, [firestore])

    return (
        <div className='wedding'>
            <div className="presentation-container">
                <pre className='tab presentation-text-kittens'>{data.message}</pre>
            </div>
            {data.cat.length > 0 ? <Wedding cat1={data.cat[0]} cat2={data.cat[1]} /> : <></>}
            {data.cat.length > 0 ? <div className='wedding-date'>{data.dateText}</div> : <></>}
            {data.cat.length > 0 ? <Kittens kittens={data.kittens} /> : <></>}
            <SalesConditions />
        </div>
    );
};

export default WeddingKittens;