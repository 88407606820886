import React from 'react';
import NavMenuLink from './NavMenuLink';

const NavMenu = ({ links, onSelectedHandle }) => {
    return (
        <div className='nav-menu'>
            <div className='sidenav'>
                <ul>
                    <br /><br />
                    {links.map((link) => <li key={link.liId}><NavMenuLink link={link} onSelectedHandle={onSelectedHandle}/></li>)}
                </ul>
            </div>
        </div>
    );
};

export default NavMenu;