import React from 'react';
import MapsContainer from './MapsContainer';

const FindUs = () => {

    return (
        <div>
            <h1>Où nous trouver ?</h1>
            <div className="map-container">
                <pre className="tab">15 Route de Saint Genis, 42800, Rive de Gier</pre>
                <MapsContainer />
            </div>
        </div>
    );
};

export default FindUs;