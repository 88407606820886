import React from 'react';
import { informationStandardText } from '../../content/information';
import InformationImage from './InformationImage';

const InformationStandard = () => {

    const rootImgPath = '/Images/Information/Standard/'
    const images1 = [{ imgPath: rootImgPath + 'img1.jpg', imgAlt: 'corps' }];
    const images2 = [{ imgPath: rootImgPath + 'img2.jpg', imgAlt: 'tête' }];
    const images3 = [{ imgPath: rootImgPath + 'img3.jpg', imgAlt: 'nez1' }, { imgPath: rootImgPath + 'img4.jpg', imgAlt: 'nez2' }];
    const images4 = [{ imgPath: rootImgPath + 'img5.jpg', imgAlt: 'queue' }];
    const images5 = [{ imgPath: rootImgPath + 'img6.jpg', imgAlt: 'gants1' }, { imgPath: rootImgPath + 'img7.jpg', imgAlt: 'gants2' }];

    return (
        <div id='cat-information-standard' className='nav-selector'>
            <br /><br />
            <h1>Standard</h1>
            <pre className="tab">{informationStandardText[0]}</pre>
            <InformationImage images={images1} />
            <pre className="tab">{informationStandardText[1]}</pre>
            <InformationImage images={images2} />
            <pre className="tab">{informationStandardText[2]}</pre>
            <InformationImage images={images3} />
            <pre className="tab">{informationStandardText[3]}</pre>
            <InformationImage images={images4} />
            <pre className="tab">{informationStandardText[4]}</pre>
            <InformationImage images={images5} />

        </div>
    );
};

export default InformationStandard;