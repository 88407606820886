import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink
} from "react-router-dom";
import Home from './Home';
import ContactUs from './ContactUs/ContactUs';
import NotFound from './NotFound';
import Legend from './Legend';
import Cattery from './Cattery/Cattery';
import Information from './Information/Information';
import WeddingKittens from './WeddingKittens/WeddingKittens';

const Body = ({ firestore, analytics }) => {

    return (
        <div id='body' className='body'>
            <Router>
                <nav className='navigation-header'>
                    <ul className='navbar'>
                        <li>
                            <NavLink activeClassName='selected' exact to="/">Accueil</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='selected' exact to="/legend">La légende</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='selected' exact to="/cat-information">Parlons birman</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='selected' exact to="/cattery">Chatterie</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='selected' exact to="/kittens">Chatons disponibles</NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName='selected' exact to="/contact">Nous contacter</NavLink>
                        </li>
                    </ul>
                </nav>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/legend">
                        <Legend />
                    </Route>
                    <Route exact path="/cat-information">
                        <Information />
                    </Route>
                    <Route exact path="/cattery">
                        <Cattery firestore={firestore} />
                    </Route>
                    <Route exact path="/kittens">
                        <WeddingKittens firestore={firestore} />
                    </Route>
                    <Route exact path="/contact">
                        <ContactUs analytics={analytics} />
                    </Route>
                    <Route>
                        <NotFound analytics={analytics} />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
};

export default Body;