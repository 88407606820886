import React from 'react';

const Photo = ({ photo }) => {
    return (
        <div className='photo'>
            <img src={photo.url} alt={photo.alt}></img>
            <p className='presentation'>{photo.text}</p>
        </div>
    );
};

export default Photo;