import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import ImageGallery from 'react-image-gallery';


const Cat = ({ data }) => {
    let [open, setOpen] = useState(false);

    // Liste des fichiers du répertoire
    let images = [];
    const imageFolder = '/Images/Cattery/' + data.short_name + '/';
    for (let i = 1; i <= 5; i++)
        images.push({ original: imageFolder + "image" + i + ".jpg", sizes:"550px"});

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const pedigree = '/Images/Pedigrees/' + data.short_name + '.jpeg';
    const born_GenderAdapt_fr = data.male ? "Né" : "Née";
    const formatedDate = new Date(data.birthdate.toDate()).toLocaleDateString("fr-FR", options);
    const detail = "(" + born_GenderAdapt_fr + " le " + formatedDate + ")";

    return (
        <div className='cat-container'>
            <div className='cat-container-title'>
                <img className='cat-container-image-pedigree' src='/Images/Pedigrees/_loof.jpg' alt='loof' onClick={() => setOpen(true)} />
                <span className='cat-container-name'>{data.name}</span>
                <span className='cat-container-details'>{detail}</span>
                <span className='cat-container-color'>{data.color}</span>
            </div>
            <div className='cat-container-body'>
                <pre className='cat-container-presentation'>{data.presentation}</pre>
                <ImageGallery
                    autoPlay={false}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    items={images} />
                {/* <img className='cat-container-image' src={imagePath} alt={data.short_name}></img> */}
            </div>

            {
                open && (
                    <Lightbox
                        mainSrc={pedigree}
                        onCloseRequest={() => setOpen(false)} />
                )
            }
        </div>

    );
};

export default Cat;