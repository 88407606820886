import React, {useState} from 'react';
import Lightbox from 'react-image-lightbox';

const WeddingCat = ({data}) => {
    let [open, setOpen] = useState(false);
    
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const imagePath = '/Images/Cattery/' + data.short_name + '/wedding.jpg';
    const pedigree = '/Images/Pedigrees/' + data.short_name + '.jpeg';
    const born_GenderAdapt_fr = data.male ? "Né" : "Née";
    const formatedDate = new Date(data.birthdate.toDate()).toLocaleDateString("fr-FR", options);
    //const detail = "(" + born_GenderAdapt_fr + " le " + formatedDate + ")";

    return (
        <div className='wedding-cat-container'>
            <div className='cat-container-title'>
                <img className='cat-container-image-pedigree' src='/Images/Pedigrees/_loof.jpg' alt='loof' onClick={() => setOpen(true)} />
                <span className='cat-container-name'>{data.name}</span>
                <span className='cat-container-color'>{data.color}</span>
            </div>
            <div className='cat-container-body'>
                <img className='wedding-cat-container-image' src={imagePath} alt={data.short_name}></img>
            </div>

            {
                open && (
                    <Lightbox
                        mainSrc={pedigree}
                        onCloseRequest={() => setOpen(false)} />
                )
            }
        </div>

    );
};

export default WeddingCat;