import React from 'react';
import { logEvent } from "firebase/analytics";


const SocialIcon = ({ analytics, iconClassName, spanClassName, url }) => {

    const analyticsEvent = 'click_' + iconClassName.replace(' ','_');

    return (
        <div className={iconClassName}>
            <a href={url} onClick={() => logEvent(analytics, analyticsEvent)}><span><i className={spanClassName}></i></span></a>
        </div>
    );
};

export default SocialIcon;