import React, { useEffect } from 'react';
import NavMenu from '../NavMenu';
import CatList from './CatList';
import Cherub from './Cherub';
import GuidedTour from './GuidedTour';

const Cattery = ({ firestore }) => {

    const links = [{ label: 'Visite guidée', elementId: 'cattery-guided-tour', liId: 'li-cattery-guided-tour' }, { label: 'Nos félins', elementId: 'cattery-cat-list', liId: 'li-cattery-cat-list' }/*, { label: 'Nos chérubins', elementId: 'cattery-cherub', liId: 'li-cattery-cherub' }*/];

    const e = document.getElementById('body');
    if (e != null)
        e.scrollIntoView({ behavior: 'smooth' });

    const onSelectedHandle = (selectedId) => {
        document.getElementById('cattery-guided-tour').classList.remove('nav-active');
        document.getElementById('cattery-cat-list').classList.remove('nav-active');
        document.getElementById('cattery-cherub').classList.remove('nav-active');
        document.getElementById(selectedId).classList.add('nav-active');
    }

    return (
        <div className='side-bar-page'>
            <NavMenu links={links} onSelectedHandle={onSelectedHandle} />
            <div id='page-content' className='page-content'>

                <GuidedTour />
                <CatList firestore={firestore} />
                <Cherub />

            </div>
        </div>
    );
};

export default Cattery;