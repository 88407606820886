import React from 'react';
import { guidedTourText } from '../../content/guidedTour';

const GuidedTour = () => {
    return (
        <div id='cattery-guided-tour' className='nav-selector nav-active'>            
            <br /><br />
            <h1>Visite guidée</h1>
            <pre className='tab'>{guidedTourText}</pre>
        </div>
    );
};

export default GuidedTour;