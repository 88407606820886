import React from 'react';
import Kitten from './Kitten';

const Kittens = ({ kittens }) => {
    return (
        <div className='kitten-list'>
            <ul>
                {kittens.map(kitten => <li key={kitten.name}><Kitten kitten={kitten} /></li>)}
            </ul>
        </div>
    );
};

export default Kittens;