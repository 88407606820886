import React from 'react';
import { informationMaintenanceText } from '../../content/information';

const InformationMaintenance = () => {
    
    return (
        <div id='cat-information-maintenance' className='nav-selector'>
             <br /><br />
            <h1>Entretien</h1>
            <pre className="tab">{informationMaintenanceText[0]}</pre>
            <pre className="tab inline">{informationMaintenanceText[1]}</pre>
            <a href="/" className="text-hyperlink inline">{informationMaintenanceText[2]}</a>
            <pre className="tab inline">{informationMaintenanceText[3]}</pre>
            <pre className="tab inline">{informationMaintenanceText[4]}</pre>
            <a href="/" className="text-hyperlink inline">{informationMaintenanceText[5]}</a>
            <pre className="tab inline">{informationMaintenanceText[6]}</pre>            
            <pre className="tab parenthesis">{informationMaintenanceText[7]}</pre>
            <pre className="tab inline">{informationMaintenanceText[8]}</pre>
            <a href="/" className="text-hyperlink inline">{informationMaintenanceText[9]}</a>
            <pre className="tab inline">{informationMaintenanceText[10]}</pre>
            <pre className="tab">{informationMaintenanceText[11]}</pre>
        </div>
    );
};

export default InformationMaintenance;