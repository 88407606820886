import React from 'react';
import InformationMaintenance from './InformationMaintenance';
import InformationPersonality from './InformationPersonality';
import InformationStandard from './InformationStandard';
import NavMenu from '../NavMenu';
import InformationPresentation from './InformationPresentation';

const Information = () => {

    const links = [
        { label: 'Presentation', elementId: 'cat-information-presentation', liId: 'li-cat-information-presentation' }, 
        { label: 'Standard', elementId: 'cat-information-standard', liId: 'li-cat-information-standard' }, 
        { label: 'Caractère', elementId: 'cat-information-personality', liId: 'li-cat-information-personality' }, 
        { label: 'Entretien', elementId: 'cat-information-maintenance', liId: 'li-cat-information-maintenance' }
    ];

    const e = document.getElementById('body')
    if (e != null)
        e.scrollIntoView({ behavior: 'smooth' });

    const onSelectedHandle = (selectedId) => {
        document.getElementById('cat-information-presentation').classList.remove('nav-active');
        document.getElementById('cat-information-standard').classList.remove('nav-active');
        document.getElementById('cat-information-personality').classList.remove('nav-active');
        document.getElementById('cat-information-maintenance').classList.remove('nav-active');
        document.getElementById(selectedId).classList.add('nav-active');
    }
    

    return (
        <div className='side-bar-page'>
            <NavMenu links={links} onSelectedHandle={onSelectedHandle}/>
            <div id='page-content' className='page-content'>
                <InformationPresentation/>
                <InformationStandard />
                <InformationPersonality />
                <InformationMaintenance />

            </div>
        </div>
    );
};

export default Information;