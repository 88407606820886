import React from 'react';
import { legendText1, legendText2, legendText3, legendText4, legendText5, legendText6 } from '../content/legendText'

const Legend = () => {

    const e = document.getElementById('body')
    if (e != null)
        e.scrollIntoView({ behavior: 'smooth' });

    return (
        <div className='legend'>
            <div className="presentation-container">
                <p className='presentation'>{legendText1}</p>
                <p className='presentation'>{legendText2}</p>
                <p className='presentation'>{legendText3}</p>
                <p className='presentation'>{legendText4}</p>
                <p className='presentation'>{legendText5}</p>
                <p className='presentation'>{legendText6}</p>
                <br /><br />
            </div>
        </div>
    );
};

export default Legend;