import React from 'react';
import { informationPresentationText } from '../../content/information';
import InformationImage from './InformationImage';

const InformationPresentation = () => {

    const e = document.getElementById('body')
    if (e != null)
        e.scrollIntoView({ behavior: 'smooth' });
        
    const rootImgPath = '/Images/Information/Presentation/'
    const images1 = [{ imgPath: rootImgPath + 'img1.jpg', imgAlt: 'cat' }];

    return (

        <div id='cat-information-presentation' className='nav-selector nav-active'>
            <br /><br />
            <h1>Présentation</h1>
            <pre className="tab">{informationPresentationText}</pre>
            <InformationImage images={images1} />
        </div>
    );
};

export default InformationPresentation;